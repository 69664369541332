import '../App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams  } from "react-router-dom";
import SwiperCore, { Navigation, Autoplay } from 'swiper';

import { ethers } from "ethers"
import { WagmiConfig, createClient, chain, useAccount, useSwitchNetwork } from "wagmi";
import { ConnectKitProvider, ConnectKitButton, getDefaultClient } from "connectkit";
import { alchemyProvider } from 'wagmi/providers/alchemy'


function App() {

  /* global BigInt */

  const [searchParams] = useSearchParams();
  const [id] = useState(searchParams.get("trade_id"));

  useEffect(() => {
    showOffers();
  }, []);

  const navigate = useNavigate();

  const [addr, setAddress] = useState('');
  const [tradeAddr, setTradeAddress] = useState('');
  const [addrTokens, setTokens] = useState('');

  const [offerTokens, setOfferTokens] = useState('');
  const [offerTokensAmount, setOfferTokensAmount] = useState('');
  const [allowance, setAllowance] = useState(0);
  const [usdc, setUSDC] = useState(0);

  const alchemyId = 'fD6Vy-JKWcvFtVP6q4Zip32cWeXXaDAG';

  const usdcConversion = 1000000;
  const weiConversion = 1000000000000000000;
  const decimals = 18;
  const approvalAmount = ethers.utils.parseUnits(JSON.stringify(1000000), decimals);
   
  const chains = [chain.arbitrum];

  const client = createClient(
      getDefaultClient({
          appName: "ARBOTC",
          alchemyId,
          chains,
      })
  );

  const Connected = () => {

    useAccount({
        async onConnect({ address }) {
          setAddress(address);
          getAllowance(address);
          checkAddr(address);
          return
        },
        onDisconnect() {
          window.location.href="/";
          return
        },
    })
  }

  const goback = () => {
    window.location.href="/";
  }

  async function showOffers(){

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    
  
    const CONTRACT_ADDRESS = new ethers.Contract(contract.address, contract.abi, provider);

    console.log(id);
    console.log(id+1);

    const offers = await CONTRACT_ADDRESS.getOffers(id,Number(id)+1);

    console.log(offers);

      document.getElementById("id").innerHTML = id;
      document.getElementById("creator").innerHTML = offers[0].creator.substring(0,4) + "..." + offers[0].creator.substring(offers[0].creator.length - 4);
      document.getElementById("tokens").innerHTML = Number(BigInt(offers[0][1]*weiConversion))/weiConversion + " $ARB";
      document.getElementById("cost").innerHTML = "" + Number(BigInt(offers[0].costPerToken))/usdcConversion + " $USDC";
      document.getElementById("total-cost").innerHTML = "" + Number(BigInt(offers[0][1]*weiConversion))/weiConversion*Number(BigInt(offers[0].costPerToken))/usdcConversion + " $USDC";
      document.getElementById("disclaimer").innerHTML = `<div>Accepting this offer will cost you <span style="font-weight:800">` + Number(BigInt(offers[0][1]*weiConversion))/weiConversion*Number(BigInt(offers[0].costPerToken))/usdcConversion + ` $USDC</span> as the Buyer and you will receive <span style="font-weight:800">` + Number(BigInt(offers[0][1]*weiConversion))/weiConversion + ` $ARB</span> minus fees when the Seller claims their airdrop and fulfils the agreed upon amount of $ARB tokens. Should the Seller fail to fulfil the agreement by the deadline (March 23, 11:59 PM UTC), the Seller will lose 100% of their deposit (25% of the Total Agreement Amount). Upon agreement failure, the buyer will receive 80% of that forfeited deposit amount upon agreement failure plus 100% of the original purchase amount back.</div>`
      setTradeAddress(offers[0].creator);
      setUSDC(Number(BigInt(offers[0][1]*weiConversion))/weiConversion*Number(BigInt(offers[0].costPerToken))/usdcConversion)
  }

  function checkAddr (address) {
    console.log(JSON.stringify(tradeAddr));
    console.log(JSON.stringify(address));
    if(JSON.stringify(tradeAddr).toLowerCase() == JSON.stringify(address).toLowerCase()){
      document.getElementById("accept-offer").classList.add("disabled-events");
      document.getElementById("accept-offer").innerHTML = "Your Offer";
    }
    else{
      document.getElementById("accept-offer").classList.remove("disabled-events");
      document.getElementById("accept-offer").innerHTML = "Confirm Accept";
    }
  }


  const MAX_AMOUNT = 2;
  const MIN_AMOUNT = 0.3;


const contract = {
    address: "0xadb79a02839f4DA7Bdf37ed1e89177864c70B440",
    abi: [
      "function createOffer(uint256 _costPerToken, uint256 _tokens) public nonReentrant",
      "function cancelOffer(uint256 tradeId) public nonReentrant",
      "function acceptOffer(uint256 tradeId) public nonReentrant",
      "function claimCollateral(uint256 agreementId) public nonReentrant",
      "function getOffers(uint256 startIndex, uint256 endIndex) public view returns (tuple(address creator, uint256 tokens, uint256 costPerToken, uint256 tradeId, bool active)[] memory)",
      "function getAgreements(uint256 startIndex, uint256 endIndex) public view returns (tuple(address seller, address buyer, uint256 tokens, uint256 costPerToken, uint256 tradeId, bool active)[] memory)"
  ]
};

const usdcContract = {
  address: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
  abi: [
      "function balanceOf(address) view returns (uint256)",
      "function approve(address spender, uint256 amount) public virtual override returns (bool)",
      "function allowance(address owner, address spender) public view virtual override returns (uint256)"
  ]
};


async function getAllowance(addr) {

    const provider = new ethers.providers.Web3Provider(window.ethereum);
  

  const CONTRACT_ADDRESS = new ethers.Contract(usdcContract.address, usdcContract.abi, provider);
  
  var allowance = await CONTRACT_ADDRESS.allowance(addr,contract.address);
  setAllowance(Number(BigInt(allowance)));
  const balance = await CONTRACT_ADDRESS.balanceOf(addr);
  document.getElementById("balance").innerHTML = "USDC Balance: " + Number(BigInt(balance))/usdcConversion;
}

class transaction {
  constructor(contractAddress, chainId, signer) {
      this.contractAddress = contractAddress;
      this.chainId = chainId;
      this.signer = signer;
  }

  static async accept() {

      const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

    
        const hash = new ethers.Contract(contract.address, contract.abi, signer);

        document.getElementById("accept-offer").classList.add("disabled-events");

          if(allowance === 0){
          document.getElementById("accept-offer").innerHTML = `Approve USDC...`;
          const approveUSDC = new ethers.Contract(usdcContract.address, usdcContract.abi, signer);
          const approveTx = await approveUSDC.approve(contract.address, approvalAmount);
          document.getElementById("accept-offer").innerHTML = `Approving USDC` + `<span className="text-loading">...</span>`;
          await approveTx.wait();
          document.getElementById("accept-offer").innerHTML = `Approve Transaction` + `<span className="text-loading">...</span>`;
          }

          const usdcBalance = new ethers.Contract(usdcContract.address, usdcContract.abi, signer);

          const balance = await usdcBalance.balanceOf(addr);
          const convertBalance = Number(BigInt(balance));

          if(convertBalance/usdcConversion <= usdc){
            document.getElementById("accept-offer").innerHTML = `Insufficent USDC Funds`
          }

          else{

          document.getElementById("accept-offer").innerHTML = `Approve Transaction` + `<span className="text-loading">...</span>`;
          const tx = await hash.acceptOffer(id);
          document.getElementById("accept-offer").innerHTML = `Accepting Offer` + `<span className="text-loading">...</span>`;
          await tx.wait();
          document.getElementById("accept-offer").innerHTML = `Success!`;
          window.location.href="/";
          }
      }



    }


  return (
    <div>

  
 

        <section id="mint" className="cs-shape_wrap_4 cs-parallax" >
        <div className="cs-height_60 cs-height_lg_80" />
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-xl-4 ">


                <section id="sale-module" className="cs-hover_tab py-5 fixedElement" >


                <div className="cs-section_heading cs-style1">
                <img style={{height:"70px"}} src="https://assets-global.website-files.com/6364e65656ab107e465325d2/637ea09ce69ec8d049fb871e_nt4Cn4mhAnXmPqN4XNw7P3C8w59Mi18UQsJr0Kv-OWU.svg"></img>
                <a href="/about"><button  id="view-how" className="cs-btn cs-style1 cs-type1" style={{marginLeft:"20px"}}>
                        <span>About</span>
                    </button></a>
                  <div className="cs-height_45 cs-height_lg_20" />
                  <WagmiConfig client={client}>
      <ConnectKitProvider theme="midnight" >
        <ConnectKitButton />
        <Connected />
      </ConnectKitProvider>
    </WagmiConfig>
    <br></br>
          <div id="balance"></div>
                </div>

                <div className="cs-iconbox_in">
                <br></br>
                <button onClick={goback} id="view-offers" className="cs-btn cs-style1 cs-type1 px-3" style={{marginRight:"10px"}}>
                        <span>Go Back</span>
                    </button>
                
                      </div>
                      </section>

                
              </div>
              <div className="col-lg-7 offset-xl-1" >
                <div className="cs-iconbox_3_list cs-style1">
   
                <section id="sale-module" className="cs-hover_tab py-5">
                <div className="cs-iconbox_in" id="offers">
                        <h2 className="cs-iconbox_title">Accept Offer</h2>
                        <div className="cs-iconbox_subtitle">
                          <div className="cs-side_header_box">
                                <div className="cs-newsletter cs-style1" id="show-trade-offers">

                                <div style={{border:"1px solid white",padding:"20px",borderRadius:"10px"}} className="cs-newsletter_form">
                <div><span style={{fontWeight:800}}>Offer Id: </span><span id="id"></span></div>
                  <div><span style={{fontWeight:800}}>Creator: </span><span id="creator"></span></div>
                  <div><span style={{fontWeight:800}}>Tokens: </span><span id="tokens"></span></div>
                  <div><span style={{fontWeight:800}}>Cost: </span><span id="cost"></span></div>
                  <div><span style={{fontWeight:800}}>Total Cost: </span><span id="total-cost"></span></div>
                  <br></br>
                  <div id="disclaimer"></div>
                  <button id="accept-offer" onClick={e => transaction.accept()} className="cs-newsletter_btn" style={{backgroundColor:"green"}}><span>Confirm Accept</span></button>
            </div>

                                </div>
                          </div>
                        </div>
                      </div>

                      </section>
                  

                </div>
              </div>
            </div>
          </div>

        </section>

      </div>

  );
}

export default App;