import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Index from './Components/index';
import Fulfill from './Components/fulfill';
import Claim from './Components/claim';
import Cancel from './Components/cancel';
import Accept from './Components/accept';
import About from './Components/about';

import "./App.css"

function App() {
  return (
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/accept" element={<Accept />} />
        <Route path="/fulfill" element={<Fulfill />} />
        <Route path="/claim" element={<Claim />} />
        <Route path="/about" element={<About />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
  );
}
export default App;